import React, { Component } from 'react'
import {Link} from 'gatsby'
import IndustryDetailsSidebar from './ServicesSidebar'
// import details from '../../assets/images/courses/courses-details.jpg'
import speaker1 from '../../assets/images/speaker/speaker1.jpg'
import user1 from '../../assets/images/user1.jpg'
import user2 from '../../assets/images/user2.jpg'
import user3 from '../../assets/images/user3.jpg'
import user4 from '../../assets/images/user4.jpg'

class IndustryDetailsContent extends Component {

    // Tab
    openTabSection = (evt, tabNmae) => {
        let i, tabcontent, tablinks;
        tabcontent = document.getElementsByClassName("tabs_item");
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].classList.remove("fadeInUp");
            tabcontent[i].style.display = "none";
        }

        tablinks = document.getElementsByTagName("li");
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace("current", "");
        }

        document.getElementById(tabNmae).style.display = "block";
        document.getElementById(tabNmae).className += " fadeInUp animated";
        evt.currentTarget.className += "current";
    }

    render() {
        return (
            <div className="courses-details-area pt-100">

                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="courses-details-desc">
                                <h3> E-Commerce Operations </h3>

                                <ul className="nav nav-tabs pt-10" id="myTab" role="tablist" >
                                    <li
                                        className="current"
                                        onClick={(e) => this.openTabSection(e, 'tab1')}
                                    >
                                        Overview
                                    </li>
                                    <li
                                        onClick={(e) => this.openTabSection(e, 'tab2')}
                                    >
                                        Headless eCommerce (PWA)
                                    </li>
                                    {/* <li
                                        onClick={(e) => this.openTabSection(e, 'tab3')}
                                    >
                                        Open Cart
                                    </li>
                                    <li
                                        onClick={(e) => this.openTabSection(e, 'tab4')}
                                    >
                                        Shopify
                                    </li> */}

                                </ul>

                                <div className="tab-content">
                                    
                                    <div id="tab1" className="tab-pane tabs_item">
                                        <div className="courses-overview">
                                            <h3>Headless eCommerce</h3>
                                            <p>Headless eCommerce makes it possibile to separate the backend and frontend of a eCommerce website.</p>
                                            <h3>Open Cart</h3>
                                            <p>OpenCart is a user-friendly solution to manage the store, the products and orders for small businesses. Its main advantage is low Total Cost of Ownership.</p>
                                            <h3>Shopify</h3>
                                            <p>Shopify gives you an access to a wide range of marketing tools. These include social media, product review and email marketing tools.</p>

                                        </div>
                                        <br></br>

                                {/* <h3>Industries</h3> */}

                                <div className="row">
                                    {/* <div className="col-lg-4 col-sm-6 col-md-6">
                                        <div className="single-industries-serve-box">
                                            <div className="icon">
                                                <i className="flaticon-factory"></i>
                                            </div>
                                            Technology
                                        </div>
                                    </div>
            
                                    <div className="col-lg-4 col-sm-6 col-md-6">
                                        <div className="single-industries-serve-box">
                                            <div className="icon">
                                                <i className="flaticon-hospital"></i>
                                            </div>
                                            Fashion
                                        </div>
                                    </div>
            
                                    <div className="col-lg-4 col-sm-6 col-md-6">
                                        <div className="single-industries-serve-box">
                                            <div className="icon">
                                                <i className="flaticon-tracking"></i>
                                            </div>
                                            Finance
                                        </div>
                                    </div> */}
            
                                    {/* <div className="col-lg-4 col-sm-6 col-md-6">
                                        <div className="single-industries-serve-box">
                                            <div className="icon">
                                                <i className="flaticon-investment"></i>
                                            </div>
                                            Banking
                                        </div>
                                    </div>
            
                                    <div className="col-lg-4 col-sm-6 col-md-6">
                                        <div className="single-industries-serve-box">
                                            <div className="icon">
                                                <i className="flaticon-house"></i>
                                            </div>
                                            Finance
                                        </div>
                                    </div>
            
                                    <div className="col-lg-4 col-sm-6 col-md-6">
                                        <div className="single-industries-serve-box">
                                            <div className="icon">
                                                <i className="flaticon-order"></i>
                                            </div>
                                            Logistics
                                        </div>
                                    </div> */}
                            </div>                                    
                                    
                        </div>

                                    <div id="tab2" className="tab-pane tabs_item">
                                        <div className="courses-curriculum">
                                            <h3>Is the Headless eCommerce Right For You?</h3>
                                            <h3>Flexibility</h3>
                                            <p>Headless is a technique with which you disconnect the backend and frontend of your webshops from each other. This has the advantage that you can be extremely flexible and that there are no longer any restrictions for your website.</p>
                                            <h3>High Performance</h3>
                                            <p>One of the biggest advantages of headless eCommerce is that you have full control over the frontend. Because we have all the knowledge such as React, PWA & SPA in-house, we make websites that are unmatchable in terms of performance.</p>
                                            <h3>Easily connect new channels</h3>
                                            <p>Online stores are extremely demanding these days. Think of links with chatbots, market places, multiple payment options, apps, integration with voice and much more. With a headless ecommerce it is much easier to integrate these channels. Nothing is too crazy for us when it comes to new channels.</p>
                                        </div>
                                    </div>

                                    {/* <div id="tab3" className="tab-pane tabs_item">
                                        <div className="courses-instructor">
                                          <h3>Python Introduction</h3>
                                            <h3>Technology</h3>
                                            <p>As executives in the technology space well know, running software, hardware and technology service businesses presents unique management challenges. We help to understand business challenges and discover opportunities to tackle them with digital tools.</p>
                                            <h3>Fashion</h3>
                                            <p>We help fashion retailers to improve their digital presence and promote their offerings.</p>
                                            <h3>Financial Services</h3>
                                            <p>We help financial institutions to manage risks and serve customers.</p>
                                        </div>
                                    </div> */}

                                    {/* <div id="tab4" className="tab-pane tabs_item">
                                        <div className="courses-reviews">
                                         <h3>Python Introduction</h3>
                                            <h3>Technology</h3>
                                            <p>As executives in the technology space well know, running software, hardware and technology service businesses presents unique management challenges. We help to understand business challenges and discover opportunities to tackle them with digital tools.</p>
                                            <h3>Fashion</h3>
                                            <p>We help fashion retailers to improve their digital presence and promote their offerings.</p>
                                            <h3>Financial Services</h3>
                                            <p>We help financial institutions to manage risks and serve customers.</p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <IndustryDetailsSidebar />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IndustryDetailsContent